import { put, takeLatest, call } from "redux-saga/effects";
import { getAllResume } from "./resumeCrud";

export const actionTypes = {
  ResumeRequested: "[Request Resume] Action",
  ResumeLoad: "[All Resume] Resume Load"
};

const initialResumeState = {
    listLoading: false,
    results: [],
    meta: {
      pagination: {}
    }
};

export const reducer = (state = initialResumeState, action) => {
    switch (action.type) {
        
        case actionTypes.ResumeLoad: {
            const { results, meta } = action.payload.resume;
            return { meta, results };
        }

      default:
        return state;
    }
};

export const actions = {
  requestResume: resume => ({ type: actionTypes.ResumeRequested, payload: { resume } }),
  fulfillResume: resume => ({ type: actionTypes.ResumeLoad, payload: { resume } })
};

export function* saga() {
  yield takeLatest(actionTypes.ResumeRequested, function* resumeRequested(action) {
    try {  
      const { data } = yield call(getAllResume, action.payload.resume);
      yield put(actions.fulfillResume(data));
    } catch (error) {
      console.log(error)
    }
  });
}
