
export default class SettingsUtils {

	constructor() {
		this.primary_color = null;
		this.secondary_color = null;

		this.gradient_first_color = null;
		this.gradient_second_color = null;

		this.logo = null;
		this.background_login = null;

		this.ios = null;
		this.android = null;
		this.terms = null;
		this.ethics_and_conduct_guidelines = null;
		this.privacy_policy = null;
		this.account_opening_proposal = null;
		this.essential_information = null;

		this.support = null;

		this.header_title = 'Acesse sua conta';
		this.footer_help = '#';
	}

    /**
     * Filtration settings
     *
     * @param _entities: any[]
     */
	async filterSettings(settings) {
		/**
		 * Set colors
		 */
		await this.setPrimaryColor(settings);
		await this.setSecondaryColor(settings);

        /**
         * Set gradients colors
         */
		await this.setGradientFirstColor(settings);
		await this.setGradientSecondColor(settings);

		/**
		 * Set Images
		 */
		await this.setLogo(settings);
		await this.setBgLogin(settings);

		/**
		 * Set Urls
		 */
		await this.setIos(settings);
		await this.setAndroid(settings);
		await this.setTerms(settings);

		/** 
		 * Header
		 */
		await this.setTitle(settings);

		/** 
		 * Footer
		 */
		await this.setHelp(settings);
		
		await this.setSupport(settings);

		return this.baseSettings();
	}

    /**
     * Busca a cor primária
     * @param settings: any[] 
     */
	async setPrimaryColor(settings) {
		const colors = await settings.filter((v, i) => { return v.name === 'primary_color' });
		this.primary_color = colors[0].data.value;
	}

    /**
     * Busca a cor secundária
     * @param settings: any[] 
     */
	async setSecondaryColor(settings) {
		const colors = await settings.filter((v, i) => { return v.name === 'secondary_color' });
		this.secondary_color = colors[0].data.value;
	}

    /**
     * Busca a cor gradient primária
     * @param settings: any[] 
     */
	async setGradientFirstColor(settings) {
		const colors = await settings.filter((v, i) => { return v.name === 'gradient1_color' });
		this.gradient_first_color = colors[0].data.value;
	}

	/**
	* Busca a cor gradient secundária
	* @param settings: any[] 
	*/
	async setGradientSecondColor(settings) {
		const colors = await settings.filter((v, i) => { return v.name === 'gradient2_color' });
		this.gradient_second_color = colors[0].data.value;
	}

	/**
	* Busca a logo principal
	* @param settings: any[] 
	*/
	async setLogo(settings) {
		const image = await settings.filter((v, i) => { return v.name === 'logo' });
		this.logo = image[0].data.value;
	}

	/**
	* Busca o background do login
	* @param settings: any[] 
	*/
	async setBgLogin(settings) {
		const image = await settings.filter((v, i) => { return v.name === 'bg_login' });
		this.background_login = image[0].data.value;
	}

	/**
	* Busca a url do aplicação ios
	* @param settings: any[] 
	*/
	async setIos(settings) {
		const image = await settings.filter((v, i) => { return v.name === 'app_ios_url' });
		this.ios = image[0].data.value;
	}

	/**
	* Busca a url do aplicação android
	* @param settings: any[] 
	*/
	async setAndroid(settings) {
		const image = await settings.filter((v, i) => { return v.name === 'app_android_url' });
		this.android = image[0].data.value;
	}

	/**
	* Busca as urls dos termos
	* @param settings: any[] 
	*/
	async setTerms(settings) {
		const terms = await settings.filter((v, i) => { return v.name === 'terms' });
		this.terms = terms[0].data.value;

		const ethics = await settings.filter((v, i) => { return v.name === 'ethics_and_conduct_guidelines' });
		this.ethics_and_conduct_guidelines = ethics[0].data.value;

		const privacy = await settings.filter((v, i) => { return v.name === 'privacy_policy' });
		this.privacy_policy = privacy[0].data.value;

		const account = await settings.filter((v, i) => { return v.name === 'account_opening_proposal' });
		this.account_opening_proposal = account[0].data.value;

		const essential = await settings.filter((v, i) => { return v.name === 'essential_information' });
		this.essential_information = essential[0].data.value;
	}

	/**
	* Seta o titulo da wallet
	* @param settings: any[] 
	*/
	async setTitle(settings) {
		const title = await settings.filter((v, i) => { return v.name === 'name' });
		this.header_title = title[0].data.value;
	}

	/**
	* Seta a url do suporte
	* @param settings: any[] 
	*/
	async setHelp(settings) {
		const help = await settings.filter((v, i) => { return v.name === 'support_url' });
		this.footer_help = (help.length > 0 ) ? (help[0].data.value) : ('#');
	}

	async setSupport(settings) {
		const url = await settings.filter((v, i) => { return v.name === 'support_url' })[0].data.value;
		const hours = await settings.filter((v, i) => { return v.name === 'support_hours' })[0].data.value;
		const mail = await settings.filter((v, i) => { return v.name === 'support_mail' })[0].data.value;
		const phone = await settings.filter((v, i) => { return v.name === 'support_phone' })[0].data.value;
		
		this.support = {
			url, 
			hours,
			mail,
			phone,
		};
	}

	async baseSettings() {
		return {
			demo: "demo1",
			urls:{
				android: this.android, 
				ios: this.ios,
				terms: this.terms,
				ethics_and_conduct_guidelines: this.ethics_and_conduct_guidelines,
				privacy_policy: this.privacy_policy,
				account_opening_proposal: this.account_opening_proposal,
				essential_information: this.essential_information,
			},
			support: this.support,
			js: {
				breakpoints: {
					sm: "576",
					md: "768",
					lg: "992",
					xl: "1200",
					xxl: "1200"
				},
				backgrounds: {
					bg_login: this.background_login,
				},
				colors: {
					theme: {
						base: {
							"white": "#ffffff",
							"primary": this.primary_color,
							"secondary": this.secondary_color,
							"success": "#1BC5BD",
							"info": "#8950FC",
							"warning": "#FFA800",
							"danger": "#F64E60",
							"light": "#F3F6F9",
							"dark": "#212121"
						},
						light: {
							"white": "#ffffff",
							"primary": this.gradient_first_color,
							"secondary": this.gradient_second_color,
							"success": "#C9F7F5",
							"info": "#EEE5FF",
							"warning": "#FFF4DE",
							"danger": "#FFE2E5",
							"light": "#F3F6F9",
							"dark": "#D6D6E0"
						},
						inverse: {
							"white": "#ffffff",
							"primary": "#ffffff",
							"secondary": "#212121",
							"success": "#ffffff",
							"info": "#ffffff",
							"warning": "#ffffff",
							"danger": "#ffffff",
							"light": "#464E5F",
							"dark": "#ffffff"
						}
					},
					gray: {
						"gray100": "#8950FC",
						"gray200": "#8950FC",
						"gray300": "#8950FC",
						"gray400": "#8950FC",
						"gray500": "#8950FC",
						"gray600": "#8950FC",
						"gray700": "#8950FC",
						"gray800": "#8950FC",
						"gray900": "#8950FC"
					}
				},
				fontFamily: "Poppins"
			},
			// == Page Splash Screen loading
			loader: {
				enabled: true,
				type: "", // default|spinner-message|spinner-logo
				logo: this.logo,
				message: "Please wait..."
			},
			// page toolbar
			toolbar: {
				display: true
			},
			header: {
				title: this.header_title,
				self: {
					width: "fluid", // fixed|fluid
					theme: "dark", // light|dark
					fixed: {
						desktop: true,
						mobile: true
					}
				},
				menu: {
					self: {
						display: true,
						layout: "default", // tab/default
						"root-arrow": false,
						"icon-style": "duotone" // duotone, line, bold, solid
					},
					desktop: {
						arrow: true,
						toggle: "click",
						submenu: {
							theme: "dark", // light|dark
							arrow: true
						}
					},
					mobile: {
						submenu: {
							theme: "dark",
							accordion: true
						}
					}
				}
			},
			subheader: {
				display: true,
				displayDesc: false,
				displayDaterangepicker: true,
				layout: "subheader-v1",
				fixed: true,
				width: "fluid", // fixed/fluid,
				clear: false,
				style: "solid" // solid/transparent
			},
			content: {
				width: "fixed" // fluid|fixed
			},
			brand: {
				self: {
					theme: "dark", // light/dark
					logo: this.logo
				}
			},
			aside: {
				self: {
					theme: "dark", // light/dark
					display: true,
					fixed: true,
					minimize: {
						toggle: true, // allow toggle
						default: false, // default state
						hoverable: true // allow hover
					}
				},
				footer: {
					self: {
						display: true
					}
				},
				menu: {
					dropdown: false, // ok
					scroll: true, // ok
					"icon-style": "duotone", // duotone, line, bold, solid
					submenu: {
						accordion: true,
						dropdown: {
							arrow: true,
							"hover-timeout": 500 // in milliseconds
						}
					}
				}
			},
			footer: {
				self: {
					fixed: true,
					width: "fluid"
				},
				help: this.footer_help,
			},
			extras: {
				search: {
					display: true,
					layout: "dropdown", // offcanvas, dropdown
					offcanvas: {
						direction: "right"
					}
				},
				notifications: {
					display: true,
					layout: "dropdown", // offcanvas, dropdown
					dropdown: {
						style: "dark" // light, dark
					},
					offcanvas: {
						directions: "right"
					}
				},
				"quick-actions": {
					display: true,
					layout: "dropdown", // offcanvas, dropdown,
					dropdown: {
						style: "dark", // light, dark
					},
					offcanvas: {
						directions: "right"
					}
				},
				user: {
					display: true,
					layout: "offcanvas", // offcanvas, dropdown
					dropdown: {
						style: "dark"
					},
					offcanvas: {
						directions: "right"
					}
				},
				languages: {
					display: true
				},
				cart: {
					display: true,
					dropdown: {
						style: "dark", // ligth, dark
					}
				},
				"quick-panel": {
					display: true,
					offcanvas: {
						directions: "right"
					}
				},
				chat: {
					display: true
				},
				toolbar: {
					display: true
				},
				scrolltop: {
					display: true
				}
			}
		};
	}
}
