import { put, takeLatest } from "redux-saga/effects";
import { getAllExtract } from "./extractCrud";

export const actionTypes = {
  ExtractRequested: "[Request Extract] Action",
  ExtractLoad: "[All Extract] Extract Load",
  UpdateFilter: "[Filter Extract] Extract Filter"
};

const initialExtractState = {
  listLoading: false,
  results: [],
  meta: {
    pagination: {}
  },
  filter: [],
};

export const reducer = (state = initialExtractState, action) => {
  switch (action.type) {

    case actionTypes.ExtractLoad: {
      const { results, meta } = action.payload.extract;
      return { meta, results };
    }

    case actionTypes.UpdateFilter: {
      const { filter } = action.payload.extract;
      state.filter = filter;

      return state;
    }

    default:
      return state;
  }
};

export const actions = {
  requestExtract: extract => ({ type: actionTypes.ExtractRequested, payload: { extract } }),
  fulfillExtract: extract => ({ type: actionTypes.ExtractLoad, payload: { extract } }),
  updateFilter: extract => ({ type: actionTypes.UpdateFilter, payload: { extract } })
};

export function* saga() {
  yield takeLatest(actionTypes.ExtractRequested, function* ExtractRequested(action) {
    try {
      const { data } = yield getAllExtract(action.payload.extract);
      yield put(actions.fulfillExtract(data));
    } catch (error) {
      console.log(error)
    }
  });
}
