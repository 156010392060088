import { put, takeLatest, call } from "redux-saga/effects";
import { 
	getAllDeposit, 
	getAllDebits, 
	makeDeposit, 
	findBill, 
	getDebitsOperation,
	resendDebitsOperation
} from "./depositCrud";
import { HTTPCodes } from "../../../../../services/enums/HTTPCodes";

export const actionTypes = {
	DepositRequested: "[Request Deposit] Action",
	DepositLoad: "[All Deposit] Deposit Load",
	MakeDepositBill: "[Make Bill] Action",
	DepositBillLoad: "[Get Bill] Bill Load",
	GetDepositBill: "[Find Bill] Find Bill",
	DepositBillLoaded: "[View bill] Deposit",
	GetAutomaticDebit: "[Get All] Automatic Debits",
	AutomaticDebitsLoaded: "[Loaded] Automatic Debits",
	AuthorizeDebits: "[Debits] Get operation",
	AuthorizeDebitsLoaded: "[Debits] Loaded operation",
	ErrorLoaded: "[Error] Loaded"
};

const initialDepositState = {
	listLoading: false,
	results: [],
	meta: {
		pagination: {}
	},
	billDeposit: {
		id: 0,
		value: 0,
	},
	newDeposit: 0,
	automaticDebits: [],
	operation: [],
	error_fields: ''
};

export const reducer = (state = initialDepositState, action) => {
	switch (action.type) {

		case actionTypes.DepositLoad: {
			const { results, meta } = action.payload.deposit;
			return { ...state, meta, results, newDeposit: 0 };
		}

		case actionTypes.DepositBillLoad: {
			const { data } = action.payload;
			return { ...state, billDeposit: data, newDeposit: 1 };
		}

		case actionTypes.DepositBillLoaded: {
			const { data } = action.payload;
			return { ...state, billDeposit: data, newDeposit: 0 };
		}

		case actionTypes.AutomaticDebitsLoaded: {
			const { data } = action.payload;
			return { ...state, automaticDebits: data };
		}

		case actionTypes.AuthorizeDebitsLoaded: {
			const { data } = action.payload;
			return { ...state, operation: data };
		}

		case actionTypes.ErrorLoaded: {
			const { error } = action.payload;
			return { ...state, error_fields: error };
		}

		default:
			return state;
	}
};

export const actions = {
	requestDeposit: deposit => ({ type: actionTypes.DepositRequested, payload: { deposit } }),
	fulfillDeposit: deposit => ({ type: actionTypes.DepositLoad, payload: { deposit } }),
	makeDeposit: value => ({ type: actionTypes.MakeDepositBill, payload: value }),
	requestBill: id => ({ type: actionTypes.GetDepositBill, payload: { id } }),
	fulfillBill: data => ({ type: actionTypes.DepositBillLoad, payload: { data } }),
	requestDebits: debits => ({ type: actionTypes.GetAutomaticDebit, payload: { debits } }),
	fulfillDebits: data => ({ type: actionTypes.AutomaticDebitsLoaded, payload: { data } }),
	authorize: data => ({ type: actionTypes.AuthorizeDebits, payload: { data } }),
	authorizeLoad: data => ({ type: actionTypes.AuthorizeDebitsLoaded, payload: { data } }),
	fetchBill: data => ({ type: actionTypes.DepositBillLoaded, payload: { data } }),
	fetchError: error => ({ type: actionTypes.ErrorLoaded, payload: { error } })
};

export function* saga() {
	yield takeLatest(actionTypes.DepositRequested, function* depositRequested(action) {
		try {
			const { data: deposit } = yield call(getAllDeposit, action.payload.deposit);
			yield put(actions.fulfillDeposit(deposit));
		} catch (err) {
			if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
				const { message } = "Serviço indisponível";
				yield put(actions.fetchError([{ message }]));
			} else {
				const { data: { field_errors } } = err.response;
				yield put(actions.fetchError(field_errors.value));
			}
		}
	});

	yield takeLatest(actionTypes.MakeDepositBill, function* makeDepositBill(action) {
		try {
			const { data } = yield call(makeDeposit, action.payload);
			yield put(actions.fulfillBill(data));
			yield actions.requestDeposit(1);
		} catch (err) {
			let message_error = '';

            switch (err.response.status) {
                case HTTPCodes.UNKNOWN_SERVER_ERROR: 
                    message_error = `[${err.response.status}] Serviço indisponível`;
                    yield put(actions.fetchError([{ message: message_error }]));
                break;

                case HTTPCodes.FORBIDDEN:
                    const { data: { message } } = err.response;
                    message_error = `[${err.response.status}] ${message}`;
                    yield put(actions.fetchError([{ message: message_error }]));

                break;
            
                default:
									const { data: { field_errors } } = err.response;
									yield put(actions.fetchError(field_errors));
                break;
            }
		}
	});

	yield takeLatest(actionTypes.GetDepositBill, function* getDepositBill(action) {
		try {
			const { data } = yield call(findBill, action.payload);
			yield put(actions.fetchBill(data));
		} catch (err) {
			if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
				const { message } = "Serviço indisponível";
				yield put(actions.fetchError([{ message }]));
			} else {
				const { data: { field_errors } } = err.response;
				yield put(actions.fetchError(field_errors.value));
			}
		}
	});

	yield takeLatest(actionTypes.GetAutomaticDebit, function* debitsRequested(action) {
		try {
			const { data: results } = yield call(getAllDebits);
			yield put(actions.fulfillDebits(results));
		} catch (err) {
			if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
				const { message } = "Serviço indisponível";
				yield put(actions.fetchError([{ message }]));
			} else {
				const { data: { field_errors } } = err.response;
				yield put(actions.fetchError(field_errors.value));
			}
		}
	});

	yield takeLatest(actionTypes.AuthorizeDebits, function* authorizeRequested(action) {
		try {
			const { data: results } = yield call(getDebitsOperation, action.payload.data);
			yield call(resendDebitsOperation, results.operation.id);
			yield put(actions.authorizeLoad(results.operation));
		} catch (err) {
			if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
				const { message } = "Serviço indisponível";
				yield put(actions.fetchError([{ message }]));
			} else {
				const { data: { field_errors } } = err.response;
				yield put(actions.fetchError(field_errors.value));
			}
		}
	});
}
