export default class ModulesUtils {

	constructor() {
		this.modules = null;
	}

    /**
     * Filtration modules
     *
     * @param _entities: any[]
     */
	async filterModules(modules) {
		return await this.formatted(modules);
	}

    /**
     * Formata os modules
     * @param modules: any[] 
     */
	async formatted(modules) {
        let arr = {};
        await modules.map((v, i) => { 
            return arr[v.name] = v.enabled; 
        });
        return arr;
	}
}
